<div class="counselor" (click)="navigate(counselor.id)">                            
    <div class="personal-info">
        <div class="basic-info">
            <img [src]="counselor.profile_img?.url ?? 'assets/images/final favicon.png'" alt="" class="profile-pic">            
            <div class="name-location">
                <div class="name">
                    {{counselor.firstName}} {{counselor.lastName}}
                </div>
                <div class="location">
                    Over {{counselor.years_in_city}} Years in {{counselor.city}}
                </div>
            </div>
        </div>
    </div>
    <div class="rating">
        <app-circular-progress-bar [score]="counselor.score"
            [backgroundStroke]="secondaryBackgroundColor" [progressStroke]="secondaryColor" [textColor]="secondaryColor">
        </app-circular-progress-bar>
    </div>
</div>